import styled from "styled-components"
// import { theme } from "./Theme"

export const StyledThanks = styled.div`
  text-align: center;

  .giphy {
    width: 100%;
    img {
      width: 100%;
    }
  }
`
